// Color options
export const colorTypeOptions = ['', 'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark']
export const outlineColorTypeOptions = [
    'outline-primary',
    'outline-secondary',
    'outline-success',
    'outline-danger',
    'outline-warning',
    'outline-info',
    'outline-light',
    'outline-dark',
]

// Text options
export const fontWeightOptions = ['lighter', 'light', 'normal', 'bold', 'bolder']
export const textAlignOptions = ['left', 'center', 'right', 'justify']

// Icon options
export const iconOptions = ['ArrowLeftCircle', 'Check', 'Cross', 'CrossCircle', 'InfoCircle']
export const iconSizeOptions = ['', 'sm', 'lg']

// Button options
export const buttonVariantOptions = [...colorTypeOptions, ...outlineColorTypeOptions, 'link']
export const buttonSizeOptions = ['', 'sm', 'lg']
export const buttonTypeOptions = ['button', 'submit', 'reset']

// Modal options
export const modalSizeOptions = ['', 'sm', 'lg', 'xl']
