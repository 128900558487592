<template>
    <div
        :class="{
            'col-xl-12': single && showSinglePrice && variant !== 'plan-card',
            'col text-left mb-3': variant === 'plan-card',
        }"
    >
        <div v-if="canTrial">
            <div v-if="variant === 'default'" class="d-flex align-items-center mt-3 justify-content-end">
                <div class="mr-3" :class="{ 'purple-loader': is_loading }"></div>

                <span class="f-14 text-danger pointer" @click="continueWithFreeVersion">Continue with Free Version</span>

                <svg
                    width="20"
                    height="20"
                    class="text-danger ml-2 pointer"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    @click="continueWithFreeVersion"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                </svg>
            </div>

            <div v-if="variant === 'top-center'" class="variant-top-center">
                <div class="d-flex align-items-center justify-content-center">
                    <div class="mr-3" :class="{ 'purple-loader': is_loading }"></div>

                    <span class="pointer free_cta" @click="continueWithFreeVersion"><u>Continue with free version</u></span>
                </div>

                <p class="paid_cta">
                    <i>or</i>
                    <br />
                    Choose from the flexible options below:
                </p>
            </div>

            <div v-if="variant === 'top-center-section'" class="top-center-section">
                <h4 class="font-weight-bold">Continue with Free Version</h4>

                <p class="cta-desc text-center">
                    Create unlimited worksheets for free. We believe in supporting educators and will always provide
                    payment-free access to make worksheets.
                </p>

                <div class="d-flex align-items-center justify-content-center">
                    <b-overlay
                        :show="is_loading"
                        rounded
                        opacity="0.6"
                        spinner-small
                        spinner-variant="primary"
                        class="my-2"
                        @hidden="$refs.button.focus()"
                    >
                        <b-button
                            ref="button"
                            :disabled="is_loading"
                            block
                            variant="success"
                            style="background-color: #3d8a5b"
                            @click="continueWithFreeVersion"
                        >
                            Use Free Plan
                        </b-button>
                    </b-overlay>
                </div>

                <p class="mb-2 cta-desc"><i>Or, explore the flexible options below:</i></p>

                <hr class="divider p-0 mt-2 mb-1" />
            </div>

            <div v-if="variant === 'top-right'" class="d-flex align-items-center mt-3 justify-content-end variant-top-right">
                <div class="mr-3" :class="{ 'purple-loader': is_loading }"></div>

                <span class="free-cta pointer" @click="continueWithFreeVersion"><u>or, continue with free version.</u></span>

                <svg
                    width="20"
                    height="20"
                    class="text-danger ml-2 pointer"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    @click="continueWithFreeVersion"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                </svg>
            </div>
        </div>
        <div v-if="variant === 'plan-card' && canTrial" class="card p-3">
            <div>
                <div class="plan-desc mb-3">
                    <p class="font-weight-bold mb-0">Free</p>
                    <p class="text-muted f-13 mb-0">
                        Create unlimited worksheets for free. We believe in supporting educators and will always provide free
                        access to make worksheets.
                        <br />
                    </p>
                </div>

                <h3 class="font-weight-bold d-inline">
                    $0
                    <span class="text-muted f-13 ml-1 font-weight-normal">/ free forever</span>
                </h3>
                <b-overlay
                    :show="is_loading"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="mt-2"
                    @hidden="$refs.button.focus()"
                >
                    <b-button ref="button" :disabled="is_loading" block variant="primary" @click="continueWithFreeVersion">
                        Sign Up
                    </b-button>
                </b-overlay>
                <p v-if="true" class="mb-0 mt-1 f-12 text-center text-white">Not sure? Try a 30-day trial!</p>
            </div>
            <hr />
            <div>
                <p class="font-weight-bold f-12 mb-2">WHAT'S INCLUDED</p>
                <div v-if="page === 'worksheet'" class="d-flex item-center f-14 text-muted">
                    <svg
                        height="20"
                        weight="20"
                        class="text-success mr-1 flex-shrink-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                    Unlimited Worksheets
                </div>
                <div v-if="page === 'bingo'" class="d-flex item-center f-14 text-muted">
                    <svg
                        height="20"
                        weight="20"
                        class="text-success mr-1 flex-shrink-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                    Unlimited Bingo Cards
                </div>
                <div v-if="page === 'flashcard'" class="d-flex item-center f-14 text-muted">
                    <svg
                        height="20"
                        weight="20"
                        class="text-success mr-1 flex-shrink-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                    Unlimited Flashcards
                </div>
                <div class="d-flex item-center f-14 text-muted">
                    <svg
                        height="20"
                        weight="20"
                        class="text-success mr-1 flex-shrink-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                    No-Ad Pledge
                </div>
                <div class="d-flex item-center f-14 text-muted">
                    <svg
                        height="20"
                        weight="20"
                        class="text-success mr-1 flex-shrink-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                    Human Support
                </div>
                <div class="d-flex item-center f-14 text-muted">
                    <svg
                        height="20"
                        weight="20"
                        class="text-success mr-1 flex-shrink-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                    Unlimited Edits
                </div>
                <div v-if="page === 'worksheet'" class="d-flex item-center f-14 text-muted">
                    <svg
                        height="20"
                        weight="20"
                        class="text-danger mr-1 flex-shrink-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    Save as 'Private'
                </div>
                <div class="d-flex item-center f-14 text-muted">
                    <svg
                        height="20"
                        weight="20"
                        class="text-danger mr-1 flex-shrink-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    Upload Images
                </div>
                <div class="d-flex item-center f-14 text-muted">
                    <svg
                        height="20"
                        weight="20"
                        class="text-danger mr-1 flex-shrink-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    Organize in Folders
                </div>
                <div class="d-flex item-center f-14 text-muted">
                    <svg
                        height="20"
                        weight="20"
                        class="text-danger mr-1 flex-shrink-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    All Format Options
                </div>
                <div class="d-flex item-center f-14 text-muted">
                    <svg
                        height="20"
                        weight="20"
                        class="text-danger mr-1 flex-shrink-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    Print without Logo
                </div>
                <div v-if="page === 'worksheet' || page === 'flashcard'" class="d-flex item-center f-14 text-muted">
                    <svg
                        height="20"
                        weight="20"
                        class="text-danger mr-1 flex-shrink-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    Unlimited Bingo Cards
                </div>
                <div v-if="page === 'worksheet' || page === 'bingo'" class="d-flex item-center f-14 text-muted">
                    <svg
                        height="20"
                        weight="20"
                        class="text-danger mr-1 flex-shrink-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    Unlimited Flashcards
                </div>
                <div v-if="page === 'bingo' || page === 'flashcard'" class="d-flex item-center f-14 text-muted">
                    <svg
                        height="20"
                        weight="20"
                        class="text-danger mr-1 flex-shrink-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    Unlimited Worksheets
                </div>
                <div class="d-flex item-center f-14 text-muted">
                    <svg
                        v-if="page === 'crossword'"
                        height="20"
                        weight="20"
                        class="text-success mr-1 flex-shrink-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>

                    <svg
                        v-else
                        height="20"
                        weight="20"
                        class="text-danger mr-1 flex-shrink-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    Unlimited Crosswords
                </div>
                <div class="d-flex item-center f-14 text-muted">
                    <svg
                        v-if="page === 'wordsearc'"
                        height="20"
                        weight="20"
                        class="text-success mr-1 flex-shrink-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>

                    <svg
                        v-else
                        height="20"
                        weight="20"
                        class="text-danger mr-1 flex-shrink-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    Unlimited Word Searches
                </div>
                <div class="d-flex item-center f-14 text-muted">
                    <svg
                        height="20"
                        weight="20"
                        class="text-danger mr-1 flex-shrink-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    Lifetime Membership
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState, mapGetters } from 'vuex'
import StatsigHelper from '../mixins/StatsigHelper'
import Common from '../mixins/Common'
import WithGoogleAds from '../mixins/WithGoogleAds'
import { PlanType } from '../objects/Plan'

export default defineComponent({
    name: 'FreeTrialCta',
    mixins: [StatsigHelper, Common, WithGoogleAds],
    props: {
        single: {
            type: Boolean,
            default: false,
        },
        showSinglePrice: {
            type: Boolean,
            default: false,
        },
        page: {
            type: String,
            default: 'worksheet',
        },
        planId: {
            type: String,
            default: null,
        },
        variant: {
            type: String,
            default: 'default',
        },
    },
    data() {
        return {
            is_loading: false,
        }
    },
    computed: {
        ...mapState(['user']),
        ...mapGetters({
            neverHadTrial: 'user/neverHadTrial',
        }),
        canTrial() {
            return this.page === 'worksheet' && this.neverHadTrial
        },
    },
    methods: {
        async continueWithFreeVersion() {
            this.is_loading = true
            await this.$eventApi.create_event('free_version_selected')
            this.logFreeSubscriptionSignup(this.page)
            this.logChoosePlan('month', 'premium', 'free trial', this.page)

            try {
                // Get the trialed status of the user before the purchase
                const userCanTrial = !this.user.user.trialed
                const userHasExistingPlan = this.user.user.has_plan

                await this.giveCardlessTrial(this.planId)

                this.initialiseConversionEvent({
                    price: 0,
                    planRecurrence: null,
                    planType: PlanType.PREMIUM,
                    userCanTrial,
                    userHasExistingPlan,
                    freeTrial: true,
                    cardless: true,
                })

                this.$emit('initiatedCardlessTrial')
                this.$bvModal.hide('checkout-modal')
                this.$bvModal.show('onboarding-complete')
            } catch (error) {
                this.$bvToast.toast('Sorry! an error occured', {
                    title: 'Success',
                    autoHideDelay: 2000,
                    variant: 'danger',
                    solid: true,
                })
            } finally {
                this.is_loading = false
            }
        },
    },
})
</script>

<style lang="scss" scoped>
.variant-top-center {
    .paid_cta {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        line-height: 18px;
    }
    .free_cta {
        color: #dc3545;
        font-size: 18px;
        line-height: 24.51px;
        font-weight: 700;
    }
}

.top-center-section {
    line-height: 18px;

    .cta-desc {
        margin: 0 auto;
        width: 43%;
        font-size: 13px;
    }
    .divider {
        margin: 0 auto;
        width: 60%;
        border-color: #cacaca;
    }

    .btn {
        padding: 0.2rem 3.5rem;
        border-radius: 5px;
    }
}

.variant-top-right {
    position: absolute;
    right: 15px;
    top: -50px;

    .free-cta {
        font-weight: 700;
        color: #dc3545;
        font-size: 16px;
        line-height: 16px;
    }
}
</style>
