import { defineComponent } from 'vue'
import { GoogleConversionValuationCalulationData } from '../objects/GoogleConversionValuationCalulationData'
import { PlanRecurrence, PlanType } from '../objects/Plan'

interface Data {
    value: number
    currency: string
}

export default defineComponent({
    name: 'WithGoogleAds',
    data(): Data {
        return {
            value: 0,
            currency: 'USD',
        }
    },
    computed: {
        subscription(): any {
            return this.$store.state.subscription as any
        },
        userHasPreviouslyPurchasedDocument(): boolean {
            return this.subscription.user.documents_purchased?.length > 1
        },
    },
    methods: {
        getFreeTrialValueMultiplierMonthAdjustment(): number {
            const d = new Date()
            switch (d.getMonth() + 1) {
                case 1:
                    return 1
                case 2:
                    return 0.99
                case 3:
                    return 0.89
                case 4:
                    return 0.84
                case 5:
                    return 0.94
                case 6:
                    return 1.04
                case 7:
                    return 1.17
                case 8:
                    return 1.24
                case 9:
                    return 1.19
                case 10:
                    return 1.06
                case 11:
                    return 0.96
                case 12:
                    return 0.92
                default:
                    return 1
            }
        },
        getMonthlyMultiplier(planType?: PlanType): number {
            switch (planType) {
                case PlanType.BASIC:
                    return 2.77
                case PlanType.STANDARD:
                case PlanType.PREMIUM:
                    return 2.89
                default:
                    return 1
            }
        },
        getAnnualMultiplier(planType?: PlanType): number {
            switch (planType) {
                case PlanType.BASIC:
                    return 0.31
                case PlanType.STANDARD:
                case PlanType.PREMIUM:
                    return 0.25
                default:
                    return 1
            }
        },
        getImmediateMonthlyMultiplier(planType?: PlanType): number {
            switch (planType) {
                case PlanType.BASIC:
                case PlanType.STANDARD:
                case PlanType.PREMIUM:
                    return 5.77
                default:
                    return 1
            }
        },
        getImmediateAnnualMultiplier(planType?: PlanType): number {
            switch (planType) {
                case PlanType.BASIC:
                case PlanType.STANDARD:
                case PlanType.PREMIUM:
                    return 0.83
                default:
                    return 1
            }
        },
        calculateValue(calculationData: GoogleConversionValuationCalulationData): number {
            let valueMultiplier: number

            // If plan is lifetime
            if (calculationData.planType === PlanType.LIFETIME) {
                valueMultiplier = 1
                return calculationData.price * valueMultiplier
            }

            // If plan is a single document purchase
            if (calculationData.planType === PlanType.SINGLE) {
                valueMultiplier = 1.1
                return calculationData.price * valueMultiplier
            }

            // If cardless free trial
            if (calculationData.freeTrial && calculationData.cardless) {
                return 3
            }

            // Else is a subscription plan
            if (calculationData.freeTrial) {
                valueMultiplier = this.getFreeTrialValueMultiplierMonthAdjustment()

                if (calculationData.planRecurrence === PlanRecurrence.MONTH) {
                    valueMultiplier *= this.getMonthlyMultiplier(calculationData.planType)
                } else {
                    valueMultiplier *= this.getAnnualMultiplier(calculationData.planType)
                }
            } else {
                if (calculationData.planRecurrence === PlanRecurrence.MONTH) {
                    valueMultiplier = this.getImmediateMonthlyMultiplier(calculationData.planType)
                } else {
                    valueMultiplier = this.getImmediateAnnualMultiplier(calculationData.planType)
                }
            }

            return calculationData.price * valueMultiplier
        },
        initialiseConversionEvent(calculationData: GoogleConversionValuationCalulationData): void {
            if (
                this.userHasPreviouslyPurchasedDocument ||
                calculationData.userHasExistingPlan ||
                !calculationData.userCanTrial
            ) {
                return
            }

            const calulatedValue = this.calculateValue(calculationData)

            this.value = Math.round(calulatedValue * 100) / 100

            this.sendConversionEvent()
        },
        sendConversionEvent() {
            // @ts-ignore
            gtag('event', 'conversion', {
                send_to: 'AW-995559008/PzjbCIu7z7IZEOCM3NoD',
                value: this.value,
                currency: this.currency,
            })

            // @ts-ignore
            uet_report_conversion(this.value, this.currency)
        },
    },
})
