<template>
    <Component :is="fullIconName" v-if="fullIconName" class="duration-0" :class="itemClass" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { iconOptions, colorTypeOptions, iconSizeOptions } from '../../mocks/StorybookOptions.mock'
import IconArrowLeftCircle from './IconArrowLeftCircle.vue'
import IconCheck from './IconCheck.vue'
import IconCross from './IconCross.vue'
import IconCrossCircle from './IconCrossCircle.vue'
import IconInfoCircle from './IconInfoCircle.vue'

export default defineComponent({
    name: 'IconLoader',
    components: {
        IconArrowLeftCircle,
        IconCheck,
        IconCross,
        IconCrossCircle,
        IconInfoCircle,
    },
    props: {
        icon: {
            type: String,
            required: true,
            validator: (value: string) => iconOptions.includes(value),
        },
        type: {
            type: String,
            default: null,
            validator: (value: string) => colorTypeOptions.includes(value),
        },
        size: {
            type: String,
            default: null,
            validator: (value: string) => iconSizeOptions.includes(value),
        },
    },
    computed: {
        fullIconName(): string | null {
            if (!this.$options.components) return null

            const iconName = `Icon${this.icon}`
            return iconName in this.$options.components ? iconName : null
        },
        itemClass(): object {
            return {
                [`text-${this.type}`]: !!this.type,
                icon: true,
                [`icon-${this.size}`]: !!this.size,
            }
        },
    },
})
</script>

<style scoped lang="scss">
svg {
    display: inline;
}

.icon {
    width: 1.5rem;
    height: 1.5rem;

    &-sm {
        width: 1.25rem;
        height: 1.25rem;
    }

    &-lg {
        width: 1.875rem;
        height: 1.875rem;
    }
}
</style>
