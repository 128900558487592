<template>
    <Modal
        id="checkout-modal"
        :loading="isLoadingCheckout"
        :size="modalSize"
        :modal-class="freePlanLayout === 'plan-card' ? 'with-plan-card' : ''"
        @hidden="closed()"
        @shown="shown"
    >
        <svg
            v-if="active_step === 'payment' && !isLoadingCheckout"
            class="position-absolute back-icon text-muted pointer"
            fill="none"
            title="Go Back"
            viewBox="0 0 24 24"
            stroke="currentColor"
            @click="
                active_step = 'subscription_type'
                setFreeTrialPeriod(0)
            "
        >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
            />
        </svg>

        <div v-show="['subscription_type', 'payment'].includes(active_step)" class="text-center mb-2">
            <div v-if="onboarding" class="row justify-content-center">
                <div :class="modalSize === 'xl' ? 'col-md-5' : 'col-md-7'">
                    <div class="row justify-content-center px-5">
                        <div class="col-md-4 px-1">
                            <b-progress height="0.4em" value="100" variant="primary"></b-progress>
                        </div>
                        <div class="col-md-4 px-1">
                            <b-progress height="0.4em" value="100" variant="primary"></b-progress>
                        </div>
                        <div class="col-md-4 px-1">
                            <b-progress height="0.4em" value="100" variant="primary" style="opacity: 0.5"></b-progress>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="active_step === 'payment'">
                <div>
                    <h2 class="font-weight-bold mb-2">Card Details</h2>
                </div>
            </div>
        </div>

        <PlanSelector
            v-show="active_step === 'subscription_type'"
            :page="page"
            :is-editor="isEditor"
            @active-step="changeActiveStep"
        />

        <PaymentDetails v-show="active_step === 'payment'" :page="page" @active-step="changeActiveStep" />

        <PlanUpgrade v-show="active_step === 'upgrade'" @active-step="changeActiveStep" />


        <PaymentConfirmation v-show="active_step === 'confetti'" :page="page" />

        <ErrorHandlingModal v-if="$modals.error_handling" :error-message="processingError"></ErrorHandlingModal>
    </Modal>
</template>

<script>
import Errors from '../../libraries/errors'
import ConfettiGenerator from 'confetti-js'
import { mapGetters } from 'vuex'
import EditPayWall from '../../mixins/EditPayWall'
import ErrorHandlingModal from '../ErrorHandlingModal.vue'
import WithCheckout from '../../mixins/WithCheckout'

import Modal from '../../stories/components/modals/Modal.vue'
import PlanSelector from '../checkout/PlanSelector.vue'
import PaymentDetails from '../checkout/PaymentDetails.vue'
import PlanUpgrade from '../checkout/PlanUpgrade.vue'
import PaymentConfirmation from '../checkout/PaymentConfirmation.vue'

export default {
    name: 'CheckoutModal',
    components: {
        ErrorHandlingModal,
        Modal,
        PaymentConfirmation,
        PlanSelector,
        PaymentDetails,
        PlanUpgrade,
    },
    filters: {
        lowerCase: function (value) {
            return !value ? '' : value.toString().toLowerCase()
        },
    },
    mixins: [WithCheckout, EditPayWall],
    props: {
        isEditor: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            // country: 'US',
            // name: '',
            // address: '',
            // zip: '',
            // city: '',
            // state: '',

            active_step: null,
            render: true,

            errors: new Errors(),
        }
    },
    computed: {
        ...mapGetters({
            onPublishing: 'document/onpublishing',
            isFlashcard: 'document/isFlashcard',
            isBingo: 'document/isBingo',
            oneClickContent: 'subscription/oneClickContent',
            hasSubscription: 'subscription/hasSubscription',
        }),
        brand() {
            if (this.page === 'account') {
                return 'worksheet'
            }
            return this.page
        },
        single() {
            return ['worksheet', 'bingo', 'flashcard'].includes(this.page)
        },
        modalSize() {
            if (!this.active_step) return 'xl'

            switch (this.active_step.toLowerCase()) {
                case 'payment':
                case 'confetti':
                    return 'lg'
                default:
                    return 'xl'
            }
        },
    },
    watch: {
        // TODO: See if we still need these
        // active_step: function (newValue) {
        //     if (newValue === 'payment') {
        //         this.$store.dispatch('cards/createStripeInput', this.$refs.card)
        //     }
        // },
        // country: function () {
        //     if (this.country !== 'US') {
        //         this.state = '' //clear out the state if country is not US
        //     }
        // },
    },
    mounted() {
        this.$store.dispatch('abtests/initialize', {
            completed: () => {
                this.getFreePlanLayout()
            },
        })
    },
    methods: {
        changeActiveStep(step) {
            this.active_step = step
        },
        shown() {
            //close all popovers
            this.$root.$emit('bv::hide::popover')

            try {
                this.logNavigateForPayment(this.page)
                if (!this.oneClickUpgrade.plan) {
                    this.setSubscriptionCycle(this.subscriptionCycleOptions[0].value)
                    this.active_step = 'subscription_type'
                    if (this.plan && this.plan.name === 'Single Document' && !this.document.id) {
                        return
                    }
                    // TODO: See why this is here
                    // this.$refs.card.innerHTML = ''
                    return
                }

                // TODO: See why this is here
                // this.$refs.card.innerHTML = ''
                this.setSubscriptionCycle(this.oneClickUpgrade.subscriptionCycle)

                if (this.oneClickUpgrade.success) {
                    // TODO: Find out what this is for. It doesn't seem to exist.
                    this.active_step = 'success_upgrade'
                } else if (this.subscriptionCycle == 'lifetime') {
                    this.active_step = 'subscription_type'
                } else {
                    this.active_step = 'payment'
                    this.subscribe(this.oneClickUpgrade.plan)
                }
            } catch (error) {
                console.error(error)
            }
        },
        closed() {
            this.$store.dispatch('document/setGoPremium', false)
            if (!this.hasSubscription) return

            if (this.plan?.interval === 'lifetime' || this.oneClickContent.plan_category === 'premium') {
                if (this.onPublishing) return this.$bvModal.show('printable-modal')
            }

            if (
                this.onPublishing &&
                this.user.user.subscription_plan.includes('Free') &&
                !this.plan.name.includes('Single')
            ) {
                return this.$bvModal.show('printable-modal')
            } else if (this.onPublishing && !this.subscription.name && this.brand.toLowerCase() == 'worksheet') {
                if (this.oneClickUpgrade.plan) return this.$bvModal.show('printable-modal')
            }
        },
        throwConfetti() {
            var confettiSessings = {
                target: 'confetti',
                max: '60',
                size: 2,
                animate: true,
                rotate: true,
                props: ['circle', 'square', 'triangle', 'line'],
                colors: [
                    [165, 104, 246],
                    [230, 61, 135],
                    [0, 199, 228],
                    [253, 214, 126],
                ],
                clock: '15',
            }
            var confetti = new ConfettiGenerator(confettiSessings)
            confetti.render()
        },
    },
}
</script>

<style lang="scss">
@import 'Scss/base.scss';

$fontSizes: 10, 11, 12, 13, 14, 15;
@each $size in $fontSizes {
    .f-#{$size} {
        font-size: #{$size}px;
    }
}
.StripeElement {
    display: flex;
    align-items: center;
    justify-content: center;

    .__PrivateStripeElement {
        width: 100%;
    }
}
.StripeElement--focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    @if $enable-shadows {
        @include box-shadow($input-box-shadow, $input-focus-box-shadow);
    } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: $input-focus-box-shadow;
    }
}

#confetti {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
}
.checkout-button {
    position: relative;

    .saving {
        color: transparent !important;
    }

    .lds-ring {
        position: absolute;
    }
}

.country_input {
    flex-shrink: 0;
    margin-right: 0.75em;
    min-width: 130px !important;
}
.cityStateZip {
    display: flex;

    .field:first-child {
        flex-grow: 1;
    }
    .field:not(:first-child) {
        width: 130px;
        margin-left: 0.75em;
    }
}
.checkout-back-button {
    position: absolute;
    left: 15px;
    top: 15px;
}

.success-step-title {
    font-weight: 300;
    font-size: 48px;
    margin: 0 0 0.5em;
}

.free-trial-period {
    font-size: 20px;
    font-weight: 600;
    margin-top: 0px;
}

.free-trial-text {
    padding-bottom: 25px;
}

.pointer {
    cursor: pointer;
}

.back-icon {
    top: 5px;
    left: 5px;
    height: 24px;
    width: 24px;
}

.lifetime-title {
    margin-bottom: 5rem;
}

.with-plan-card .modal-dialog {
    min-width: 1340px !important;
    .col {
        padding-right: 10px;
        padding-left: 10px;
    }
}

@media (max-width: 1200px) {
    .modal-open .with-plan-card {
        overflow-x: scroll;
        margin-left: 10px;
        margin-right: 10px;
    }
}

a {
    color: var(--primary);
}
</style>
